.otpheader{
    text-align: center;
}
.otpheader img{
    width: 100%;
    max-width: 200px;
}
/*#partitioned {*/
/*    padding-left: 8px;*/
/*    text-align: center;*/
/*    letter-spacing: 42px;*/
/*    border: 0;*/
/*    background-image: linear-gradient(to left, black 70%, rgba(255, 255, 255, 0) 0%);*/
/*    background-position: bottom;*/
/*    background-size: 50px 1px;*/
/*    background-repeat: repeat-x;*/
/*    background-position-x: 35px;*/
/*    width: 65%;*/
/*}*/
/**:focus {*/
/*    outline: none;*/
/*}*/