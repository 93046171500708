@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css');

.cusRow {
    margin: 0 -5px;
}

.cusCol {
    padding: 0 5px;
}

.mbContainer {
}

.mbContainer .singleTv {
    max-width: 20%;
    width: 100%;
    flex: 0 0 20%;
    margin-bottom: 15px;
    padding: 0 5px;
}

.mbContainer .singleTv .title {
    font-size: 8px;
    color: #666;
    text-align: center;
}

.mbContainer .singleTv .itemImg {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    padding: 7px;
    background: #fff;
    border-radius: 10px;
    margin-bottom: 5px;
}

.mbContainer .singleTv .itemImg img {
    border-radius: 10px;
}

@media (max-width: 320px) {
    .mbContainer .singleTv .itemImg {
        width: 50px;
        height: 50px;
    }
}
.topBar {
    background: #fff;
    padding: 20px 15px;
}

.topBarInner {
    display: flex;
    justify-content: space-between;
}

.homeIcon {
    font-size: 25px;
    color: black;
}

.title {
    font-size: 18px;
    font-weight: 600;
}
.title .badge {
    margin-left: 10px;
}


/*new tv*/
.tvWrap {

}
.tvSlide {
    overflow-x: scroll;
    overflow-y: hidden;
    display: flex;
    margin: 0 -5px;
}

.tvSlide::-webkit-scrollbar {
    display: none;
}

.tvSlide {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.tvItem {
    min-width: 335px;
    width: 100%;
    padding: 0 5px;
}

.tvItem .itemImg {
    min-height: 178px;
    max-height: 178px;
    overflow: hidden;
}

.tvItem .itemImg img {
    height: 100%;
    width: 100%;
}

.info {
    margin-top: 10px;
    padding: 5px 15px 10px;
}

.tvItem .title {
    color: #878585;
    font-size: 12px;
}

.tvcusCol {
    padding: 0 5px;
}