@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700&display=swap');
@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.css');

body {
    background-color: #e4e4e4;
    font-family: Open Sans;
    max-width: 414px;
    width: 100%;
    margin: 0 auto;
}

a {
    text-decoration: none !important;
}

.cusRow {
    margin: 0 -5px;
}

.cusCol {
    padding: 0 5px;
}

.mbContainer {
}

.mbContainer .singleItem {
    max-width: 20%;
    width: 100%;
    flex: 0 0 20%;
    margin-bottom: 15px;
    padding: 0 5px;
}

.mbContainer .singleItem .title {
    font-size: 8px;
    color: #666;
    text-align: center;
}

.mbContainer .singleItem .itemImg {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    padding: 7px;
    background: #fff;
    border-radius: 10px;
    margin-bottom: 5px;
}

.mbContainer .singleItem .itemImg img {
    border-radius: 10px;
}

.whatsNewWrap {

}

.whatsNewItemInner {
    background: #fff;
    border-radius: 10px;
    margin-bottom: 15px;
    overflow: hidden;
    display: block;
}

.whatsNewItemSlide {
    overflow-x: scroll;
    overflow-y: hidden;
    display: flex;
    margin: 0 -5px;
}

.whatsNewItemSlide::-webkit-scrollbar {
    display: none;
}

.whatsNewItemSlide {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.whatsNewItem {
    min-width: 335px;
    width: 100%;
    padding: 0 5px;
}

.whatsNewItem .itemImg {
    min-height: 178px;
    max-height: 178px;
    overflow: hidden;
}

.whatsNewItem .itemImg img {
    height: 100%;
    width: 100%;
}

.info {
    margin-top: 10px;
    padding: 5px 15px 10px;
}

.whatsNewItem .title {
    color: #878585;
    font-size: 12px;
}

.exPlore .linked {
    font-size: 13px;
    color: red;
    letter-spacing: .5px;
}

.exPlore span {
    font-size: 14px;
    color: #878585;
}

h2 {
    font-size: 14px;
    font-weight: 300;
    color: #666;
    text-align: left;
    letter-spacing: 0.5px;
    margin-top: 20px;
}

.title {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    max-height: 42px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.content {
    padding-bottom: 30px;
}

/*  robiNewsWrap  */
.robiNewsWrap {

}

.robiNewsWrapItemSlide {
    overflow-x: scroll;
    overflow-y: hidden;
    display: flex;
    margin: 0 -5px;
}

.robiNewsWrapItemSlide::-webkit-scrollbar {
    display: none;
}

.robiNewsWrapItemSlide {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.robiNewsWrapItem {
    min-width: 335px;
    width: 100%;
    padding: 0 5px;
    position: relative;
}

.robiNewsWrapItem .shareIcon {
    position: absolute;
    right: 20px;
    bottom: 10px;
    font-size: 14px;
    color: #999;
}

.news24Item {
    position: relative;
}

.news24Item .shareIcon {
    position: absolute;
    right: 20px;
    bottom: 10px;
    font-size: 14px;
    color: #999;
}


.robiNewsWrapItemInner {
    background: #fff;
    display: block;
    padding: 10px;
    border-radius: 10px;
}

.robiNewsWrapItemInner img {
    max-width: 50px;
    /*width: 100%;*/
    /*height: 40px;*/
    border-radius: 8px;
}

.itemInnerWrap {
}

.itemInnerWrap .info {
    margin: 0;
    padding: 0 0 0 10px;
}

.itemInnerWrap .info p {
    font-size: 10px;
    color: #666;
    margin: 0;
}

.itemInnerWrap .info .title {
    font-size: 14px;
    color: #444;
}


/*   history_33    */
.history_33 {
}


.history_33ItemSlide {
    overflow-x: scroll;
    overflow-y: hidden;
    display: flex;
    margin: 0 -5px;
}

.history_33ItemSlide::-webkit-scrollbar {
    display: none;
}

.history_33ItemSlide {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.history_33Item {
    min-width: 335px;
    width: 100%;
    padding: 0 5px;
    position: relative;
}

.history_33ItemInner {
    background: #fff;
    display: block;
    border-radius: 10px;
    overflow: hidden;
}

.history_33ItemInner .info {
    margin: 0;
    padding: 20px;
}

.history_33ItemInner .info .title {
    font-size: 14px;
    color: #444;
    margin-bottom: 10px;
}

.history_33ItemInner .info p {
    font-size: 10px;
    color: #666;
    margin: 0 0 10px;
}

.history_33ItemInner .itemImg {
    max-width: 150px;
    width: 100%;
    height: 100%;
    margin-left: auto;
}

.history_33ItemInner .itemImg img {
    height: 175px;
    width: 100%;
}

/**
news24Item
 */
.news24Item {
    margin-bottom: 10px;
}

/*    .news24Item .shareIcon {
        position: absolute;
        right: 20px;
        bottom: 10px;
        font-size: 14px;
        color: #999;
    }*/

.searchBox {
    position: relative;
}

.searchBox input.form-control {
    border-radius: 50px !important;
    height: 50px;
}

.searchBox .btn {
    position: absolute;
    top: 0;
    right: 0;
    width: 70px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    color: #f3414b;
}

.durontoSearchBox {
    padding: 20px 0;
}

@media (max-width: 320px) {
    .mbContainer .singleItem .itemImg {
        width: 50px;
        height: 50px;
    }
}

button:focus {
    box-shadow: none;
    outline: none;
}

.searchBox {
    position: relative;
    z-index: 1;
}

.searchBox .form-control {
    background: transparent;
    padding: 0 30px;
}

.searchBox .form-control:focus {
    border-color: #666;
    box-shadow: none !important;
}

.tabHeader {
    position: sticky !important;
    top: 0;
}

.searchBox .searchIcon {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 70px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.5;
    z-index: -1;
}

.searchBox .searchIcon i {
    color: #f20101;
}

.success_img {
    background: url("http://cms.duronto.com.bd/backend/dist/img/success.jpg");
    height: 90vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.success_text {
    margin-bottom: 550px;
}

.error_img {
    background: url("http://cms.duronto.com.bd/backend/dist/img/404.png");
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.error_text {
    margin-top: 300px; 
}